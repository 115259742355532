import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const TermsOfUsePage = () => (
  <Layout>
    <SEO title="Terms of Use" />
    <h1 className="title">Terms of Use</h1>
    <hr />
    <div className="content">
      <p>
        These terms and conditions outline the rules and regulations for the use of Karim Moulai's Website, located at
        Karimmoulai.com.
      </p>
      <p>
        By accessing this website we assume you accept these terms and conditions. Do not continue to use the Service if
        you do not agree to take all of the terms and conditions stated on this page.
      </p>
      <p>
        The following terminology applies to these Terms and Conditions, Privacy Policy and all Agreements: "Client",
        "You" and "Your" refers to you, the person on this website and compliant to the Company's terms and conditions.
        "The Company", "Ourselves", "We", "Our" and "Us", refers to Grafig Ltd, a United Kingdom registered company,
        number 09512545. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the
        offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the
        Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of
        provision of the Company's stated services, in accordance with and subject to, prevailing law of the United
        Kingdom. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she
        or they, are taken as interchangeable.
      </p>
      <h3 className="subtitle">Cookies</h3>
      <p>
        We employ the use of cookies. By accessing Karimmoulai.com, you agreed to use cookies in agreement with our{' '}
        <Link to="/privacy-policy/">Privacy Policy</Link>.
      </p>
      <p>
        Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by
        our website to enable the functionality of certain areas to make it easier for people visiting our website. Some
        of our partners (tracking, statistics, affiliate, advertising, and others) may also use cookies.
      </p>
      <h3 className="subtitle">License</h3>
      <p>
        Unless otherwise stated, Grafig and/or its licensors own the intellectual property rights for all material on
        the Service. All intellectual property rights are reserved.
      </p>
      <p>You must not:</p>
      <ul>
        <li>Republish material from Grafig</li>
        <li>Sell, rent or sub-license material from Grafig</li>
        <li>Reproduce, duplicate or copy material from Grafig</li>
        <li>Redistribute content from Grafig</li>
      </ul>
      <p>This Agreement shall begin on the date hereof.</p>
      <p>
        Parts of this website offer an opportunity for users to post and exchange opinions and information in certain
        areas of the website. Grafig does not filter, edit, publish or review Comments prior to their presence on the
        website. Comments do not reflect the views and opinions of Grafig, its agents, partners and/or affiliates.
        Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted
        by applicable laws, Grafig shall not be liable for the Comments or for any liability, damages or expenses caused
        and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
      </p>
      <p>
        Grafig reserves the right to monitor all Comments and to remove any Comments which can be considered
        inappropriate, offensive or causes breach of these Terms and Conditions.
      </p>
      <p>You warrant and represent that:</p>
      <ul>
        <li>
          You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
        </li>
        <li>
          The Comments do not invade any intellectual property right, including without limitation copyright, patent or
          trademark of any third party;
        </li>
        <li>
          The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which
          is an invasion of privacy; and
        </li>
        <li>
          The Comments will not be used to solicit or promote business or custom or present commercial activities or
          unlawful activity.
        </li>
      </ul>
      <p>
        You hereby grant Grafig a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce
        and edit any of your Comments in any and all forms, formats or media.
      </p>
      <h3 className="subtitle">Hyperlinking to our Content</h3>
      <p>The following organisations may link to our Website without prior written approval:</p>
      <ul>
        <li>Government agencies;</li>
        <li>Search engines;</li>
        <li>News organisations;</li>
        <li>
          Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of
          other listed businesses; and
        </li>
        <li>
          System wide Accredited Businesses except soliciting non-profit organisations, charity shopping malls, and
          charity fundraising groups which may not hyperlink to our Web site.
        </li>
      </ul>
      <p>
        These organisations may link to our home page, to publications or to other Website information so long as the
        link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the
        linking party and its products and/or services; and (c) fits within the context of the linking party's site.
      </p>
      <p>We may consider and approve other link requests from the following types of organisations:</p>
      <ul>
        <li>commonly-known consumer and/or business information sources;</li>
        <li>dot.com community sites;</li>
        <li>associations or other groups representing charities;</li>
        <li>online directory distributors;</li>
        <li>internet portals;</li>
        <li>accounting, law and consulting firms; and</li>
        <li>educational institutions and trade associations.</li>
      </ul>
      <p>
        We will approve link requests from these organisations if we decide that: (a) the link would not make us look
        unfavorably to ourselves or to our accredited businesses; (b) the organisation does not have any negative
        records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Grafig;
        and (d) the link is in the context of general resource information.
      </p>
      <p>
        These organisations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not
        falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c)
        fits within the context of the linking party's site.
      </p>
      <p>
        If you are one of the organisations listed in paragraph 2 above and are interested in linking to our website,
        you must inform us by contacting us. Please include your name, your organisation name, contact information as
        well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the
        URLs on our site to which you would like to link.
      </p>
      <p>Approved organisations may hyperlink to our Website as follows:</p>
      <ul>
        <li>By use of our corporate name; or</li>
        <li>By use of the uniform resource locator being linked to; or</li>
        <li>
          By use of any other description of our Website being linked to that makes sense within the context and format
          of content on the linking party's site.
        </li>
      </ul>
      <p>No use of Grafig's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
      <h3 className="subtitle">iFrames</h3>
      <p>Without prior approval and written permission, you may not create frames around our Webpages.</p>
      <h3 className="subtitle">Content Liability</h3>
      <p>
        We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend
        us against all claims that is rising on your Website. No link(s) should appear on any Website that may be
        interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the
        infringement or other violation of, any third party rights.
      </p>
      <h3 className="subtitle">Your Privacy</h3>
      <p>
        Please read our <Link to="/privacy-policy/">Privacy Policy</Link>.
      </p>
      <h3 className="subtitle">Reservation of Rights</h3>
      <p>
        We reserve the right to request that you remove all links or any particular link to our Website. You approve to
        immediately remove all links to our Website upon request. We also reserve the right to amend these terms and
        conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to
        and follow these linking terms and conditions.
      </p>
      <h3 className="subtitle">Removal of links from our website</h3>
      <p>
        If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any
        moment. We will consider requests to remove links but we are not obligated to or so or to respond to you
        directly.
      </p>
      <p>
        We do not ensure that the information on this website is correct, we do not warrant its completeness or
        accuracy; nor do we promise to ensure that the website remains available or that the material on the website is
        kept up to date.
      </p>
      <h3 className="subtitle">Disclaimer</h3>
      <p>
        To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions
        relating to our website and the use of this website. Nothing in this disclaimer will:
      </p>
      <ul>
        <li>limit or exclude our or your liability for death or personal injury;</li>
        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
        <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
      </ul>
      <p>
        The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are
        subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including
        liabilities arising in contract, in tort and for breach of statutory duty.
      </p>
      <p>
        As long as the website and the information and services on the website are provided free of charge, we will not
        be liable for any loss or damage of any nature.
      </p>
      <h3 className="subtitle">Contact Us</h3>
      <p>If you have any questions or suggestions about our Terms of Use, do not hesitate to contact us.</p>
    </div>
  </Layout>
);

export default TermsOfUsePage;
